<template>
  <div class="Thanks content py-16">
    <Logo class="logo" />

    <div class="inner">
      <h1>
        {{ $t("thanks.title") }} {{ $t("thanks.sub") }}
      </h1>

    </div>

    <go to="/" class="button">
      {{ $t("thanks.action") }}
    </go>

  </div>
</template>

<script>
import Logo from '@/assets/images/UPD-Logo-White.svg';

export default {
  components: { Logo },
  mounted() {
    this.$store.commit('resetState');
  },
};
</script>

<style lang="scss" scoped>
.Thanks {
  background: var(--success-bg);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  h1,
  .h2 {
    color: white;
  }
}

.logo {
  width: 8rem;
}

.inner {
  width: 40rem;
  margin-top: auto;
  margin-bottom: auto;
}

h1 {
  font-size: 2rem;
  line-height: 1.2;
  font-weight: bold;
}
.sub {
  font-size: 1.5rem;
  line-height: 1.2;
  color: white;
}
</style>
